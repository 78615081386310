#about{
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 25px;
    margin-top: -100px;
    padding: 150px 10px 100px;
    background-color: #EFEFEF;
}

@media (max-width: 480px) {
    #about{

        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 25px;
        margin-top: -100px;
        padding: 150px 10px 50px;
        background-color: #EFEFEF;
        margin-bottom: -120px;
    }

    .w-75{
        width: 90% !important;
    }


      /* Media Query for low resolution  Tablets, Ipads */
     
 
  }

  @media (min-width: 481px) and (max-width: 767px) {
       
    .w-75{
        width: 80% !important;
    }
}

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px){

    .w-75{
        width: 90% !important;
    }
}