/* 15. Gallery */

#gallery{
    background-color: #EFEFEF;
   margin-top: 100px;
   margin-bottom: 100px;
   max-width: 100%;
}


/* .gallery-area{
	padding: 20px;
} */

.gallery-bg {
	/* background-image: url(); */
	background-size: cover;
	background-position: center;
	border-bottom: 1px solid #f2f2f2;
}
/* .single-gallery-img {
	width: 80%;
} */
.single-gallery-img {
	display: block;
	position: relative;
}
.single-gallery-img::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: .4s ease-in-out;
	opacity: 0;
}
.gallery-overlay {
	position: absolute;
	left: 55px;
	top: 50px;
	z-index: 9;
	transform: translateX(-30px);
	transition: .4s ease-in-out;
	opacity: 0;
}
.gallery-overlay-title {
	font-size: 24px;
	font-weight: 800;
	color: #fff;
	padding-bottom: 15px;
	position: relative;
	margin-bottom: 20px;
}
.gallery-overlay-title a {
	display: inline-block;
}
.gallery-overlay-title a:hover {
	color: #fff;
}

.Ghover {
    color: white;
    margin-left: 35px;
    margin-top: 35px;
}

.Ghover span{
    color: white;
    margin-left: 35px;
}