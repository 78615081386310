.link {
 color: black;
 cursor: pointer;
}

.link:hover{
    color: red;
}

#errors{
    color: #F31A23;
    font-size: 10px;
    margin-bottom: 10px;
    
}

.forms {  
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    flex-direction: column;
}
.login-form-content{
    visibility: hidden;
} 

.signup-form-content{
    visibility: hidden;
}
.form-content{
    justify-content: center;
    align-items: center;
    max-width: 430px;
    width: 100%;
    padding: 30px;
    border-radius: 6px;
    background: #FFF;
}

.field-colummn{
    display: flex;
    flex-direction: column;
}
.forms.signup{
    opacity: 0;
    pointer-events: none;
}
.formss.show-signup .forms.signup{
    opacity: 1;
    pointer-events: auto;
}
.formss.show-signup .forms.login{
    opacity: 0;
    pointer-events: none;
}
.headers{
    height: 50px;
    display: block;
   
}
.headers-content{
    display: flex;
    align-items: center;
    justify-content: center;

}
.logerror{
    color: #F31A23;
}

.forms .field{
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 30px;
    border-radius: 6px;
}
.field input,
.field button{
  margin-top: 30px;
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    font-weight: 400;
   
}

.field input-field{
    width: 100%;
}
.field input{
    width: 100%;
    outline: none;
    padding: 0 15px;
    margin: 0px;
    border-bottom: 1px solid#CACACA;
}
.field input:focus{
    border-bottom-width: 2px;
}

.field button{
    color: #fff;
    background-color: #F31A23;
    font-size: 18pt;
    transition: all 0.3s ease;
    cursor: pointer;
}
.field button:hover{
    background-color: #3E3E3E;
}

.field-inside{
    margin: 0px 5px;
}

.formstype{
    color: #cc0202;
    cursor: pointer;
}

.forget{
    color: blue;
    margin: 5px 0 -10px 4px;
}

.formstype-container{
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: auto;
    height: 20px;
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    word-spacing: px;
}
.formor{
    color: #3E3E3E;
}

.picon{
    float: right;
    top: 0;
    margin-top: -30px;
    z-index: 100;
}
.btn{
    margin-right: 10px;
}

.password{
    display: block;
    margin-top: 50px;
    z-index: 1;
}

@media screen and (max-width: 420px) {
    .form{
        padding: 20px 10px;
    }

    .formstype-container{
        width: auto;
        height: 20px;
        margin-top: 20px;
        word-spacing: 2px;
    }
    .navnodisplay{
        display: none !important; 
    }

    .mbtn{
        display: block;
        float: left;
    }
    
}

@media screen and (max-width: 320px) {
    .form{
        padding: 20px 10px;
    }

    .formstype-container{
        display: flex;
       
    }

    .navnodisplay{
        display: none;
    }

    .mbtn{
        display: block;
        float: left;
    }
    
}