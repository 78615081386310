.lds-spinner {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 10px;
    height: 20px;
    margin-top: -50px;
    margin-left: 130px;
  }

  
@media screen and (max-width: 400px) {
    .lds-spinner {
        display: flex;
    
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-top: -50px;
        margin-left: 80px;
      }

      .lds-spinner div:after {
        content: " ";
        display: block;
        top: 10px;
        left: 37px;
        width: 6px;
        height: 10px;
        border-radius: 10%;
        background: rgb(2, 0, 0);
      }
    
    
}
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: fixed;
    top: 15px;
    left: 37px;
    width: 5px;
    height: 10px;
    border-radius: 10%;
    background: rgb(255, 255, 255);
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }