.link {
    color: black;
   }
   
   .link:hover{
       color: red;
   }

   
.new_footer_area {
    background: #fbfbfd;
}

.new_footer_top {
    padding: 120px 0px 270px;
    position: relative;
      overflow-x: hidden;
}
.new_footer_area .footer_bottom {
    padding-top: 5px;
    padding-bottom: 5px;
}
.footer_bottom {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #7f88a6;
    padding: 27px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.footer_content{
    display: flex;
}

.new_footer_top .company_widget p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #6a7695;
    margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
    border-width: 1px;
    margin-top: 20px;
}
.btn_get_two:hover {
    background: transparent;
    color: #5e2ced;
}
.btn_get:hover {
    color: #fff;
    background: #6754e2;
    border-color: #6754e2;
    -webkit-box-shadow: none;
    box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
    text-decoration: none;
    outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
    color: #F31A23;
}
.new_footer_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
    margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
    margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
    margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: transparent;
    border: 1px solid #e2e2eb;
    font-size: 24px;
}
.f_social_icon a {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ti-facebook:before {
    content: "\e741";
}
.ti-twitter-alt:before {
    content: "\e74b";
}
.ti-vimeo-alt:before {
    content: "\e74a";
}
.ti-pinterest:before {
    content: "\e731";
}

.btn_get_two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #5e2ced;
    border-color: #5e2ced;
    color: #fff;
}

.btn_get_two:hover {
    background: transparent;
    color: #F31A23;
}

.new_footer_top .f_social_icon a:hover {
    background: #F31A23;
    border-color: #F31A23;
  color:white;
}
.new_footer_top .f_social_icon a + a {
    margin-left: 4px;
}
.new_footer_top .f-title {
    margin-bottom: 30px;
    color: #263b5e;
}
.f_600 {
    font-weight: 600;
}
.f_size_18 {
    font-size: 18px;
}
h1, h2, h3, h4, h5, h6 {
    color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
    color: #6a7695;
}


.new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url("../../public/assets/images/footer_bg.png") no-repeat scroll center 0;
    width: 100%;
    height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
    background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif") no-repeat center center;
    width: 330px;
    height: 105px;
  background-size:100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}


@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}


@media screen and (max-width: 900px) {
    .small {
        display: flex;
        max-width: 100%;
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }

   .footersmallscreen{
   float: left;
   width: 50%;
   }

   .nodisplay{
    display: none;
   }

   .new_footer_top .f_social_icon a {
    width: 30px;
    height: 30px;
    line-height: 28px;
    background: transparent;
    border: 1px solid #e2e2eb;
}


}


@media screen and (max-width: 480px) {
    .small {
      
        max-width: 100%;
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
   .row>* {
   display: flex;
   }

   .footersmallscreen{
   float: left;
   width: 50%;
   }

   .nodisplay{
    display: none;
   }

   .new_footer_top .f_social_icon a {
    width: 30px;
    height: 30px;
    line-height: 28px;
    background: transparent;
    border: 1px solid #e2e2eb;
}


}

@media screen and (max-width: 320px) {
  
    .small {
      
        max-width: 100%;
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
   .row>* {
   display: flex;
   }

   .footersmallscreen{
   float: left;
   width: 50%;
   }

   .nodisplay{
    display: none;
   }

   .new_footer_top .f_social_icon a {
    width: 28px;
    height: 28px;
    line-height: 26px;
    background: tr;
    border: 1px solid #e2e2eb;
}

.f_social_icon a {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 28px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
}

/*************footer End*****************/

