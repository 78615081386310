/*  Services */

.services-area{
    width: 100%;
    margin-top: 150px !important;
    margin-bottom: 100px;
    padding: auto;
}

.single-services {
	border-radius: 5px;
	box-shadow: 0px 0px 30px 0px rgba(40, 11, 72, 0.11);
	background: #fff;
}

.section-title{
	margin: 50px;
}

.services-thumb img {
	width: 100%;
}

.services-content {
	padding: 50px 30px 35px;
	border-top: 4px solid transparent;
	transition: .3s linear;
	position: relative;
	z-index: 1;
}

.services-icon {
	height: 100px;
	width: 115px;
	text-align: center;
	line-height: 82px;
	background: #fff;
	border-radius: 20%;
	font-size: 40px;
	color: #F31A23;
	position: relative;
	margin-bottom: -30px;
	z-index: -1;
	margin-top: -60px;
}

.services-icon i {
	line-height: 1;
}

.services-content h3 {
	font-size: 18px;
	margin-bottom: 5px;
}

.services-content h3 a {
	display: inline-block;
}

.services-content h3 a:hover {
	color: #F31A23;
}

.services-content span {
	font-size: 15px;
	color: #F31A23;
	font-weight: 900;
	font-family: 'Playfair Display', serif;
	display: block;
	margin-bottom: 20px;
}

.services-content p {
	margin-bottom: 0;
}

.services-content::before {
	content: "";
	position: absolute;
	height: 115px;
	width: 120px;
	background: #F31A23;
	border-radius: 20%;
	left: 28px;
	top: -30px;
	transition: .3s linear;
	z-index: -2;
	opacity: 0;
}

.services-content::after {
	content: "";
	position: absolute;
	left: 25px;
	top: 0;
	height: 85px;
	width: 130px;
	background: #fff;
	z-index: -2;
}

.single-services:hover .services-content {
	border-color: #ff4800;
}

.single-services:hover .services-content::before {
	opacity: 1;
}

@media (max-width: 480px) {
    .services-wrapper{
		padding: 20px;
	}

	.service-main{
		margin-bottom: 20px;
	}

    .service-container{
		margin-top: 1 !important;
		margin-bottom: -20px;
	  }
	 #Services{
	  padding: 0;
	  margin-bottom: -150px;
	
	 }
	 .services-area{
	  padding: 0;
	 }

      /* Media Query for low resolution  Tablets, Ipads */
 
  }

  @media (min-width: 481px) and (max-width: 767px) {
	.services-wrapper{
		padding: 20px;
	}

	.service-main{
		margin-bottom: 20px;
	}

    .service-container{
		margin-top: 1 !important;
		margin-bottom: -20px;
	  }

	 #Services{
	  padding: 0;
	  margin-bottom: -150px;
	 }
	 .services-area{
	  padding: 0;
	 }
 
}

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px){


 
   
}
