
.contact-us {
    padding: 1rem;
    color: #212d31;
    font-family: 'Roboto', sans-serif;
  }

.contain {
    background-color: white;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
  }
  
  div.form {
    background-color: white;
  }

  .contact-wrapper {
    margin: auto 0;
  }
  
  .submit-btn {
    float: left;
  }
  .reset-btn {
    float: right;
  }
  
  .form-headline:after {
    content: "";
    display: block;
    width: 10%;
    padding-top: 10px;
    border-bottom: 3px solid #ec1c24;
  }
  
  .highlight-text {
    color: #ec1c24;
  }
  
  .hightlight-contact-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
  }
  
  .highlight-text-grey {
    font-weight: 500;
  }
  
  .email-info {
      margin-top: 20px;
  }
  
  ::-webkit-input-placeholder { /* Chrome */
    font-family: 'Roboto', sans-serif;
  }
  
  .required-input {
    color: black;
  }
  @media (min-width: 600px) {
    .contain {
      padding: 0;
    }
  }
  
  h3,
  ul {
    margin: 0;
  }
  
  h3 {
    margin-bottom: 1rem;
  }

  .form-input{
    width: 100%;
    border: 1px solid black;
    height: 40px;
    padding-left: 10px;
    outline: 0;
    border-radius: 5px;
  }

  .textarea {
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;
  }


  .wrapper > * {
    padding: 1em;
  }
  @media (min-width: 700px) {
    .wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    .wrapper > * {
      padding: 2em 2em;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .contacts {
    color: #212d31;
  }
  
  .form {
    background: #fff;
  }
  
  .cform {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .cform label {
    display: block;
  }
  .cform p {
    margin: 0;
  }
  
  .full-width {
    grid-column: 1 / 3;
    border: none;
  }
  
 
